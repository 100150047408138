import { useState, useEffect } from 'react'
import { usersService } from '../services/usersService';

export function useUsers () {
    const {getUsers} = usersService();
    const [usersList, setUsersList] = useState([]);

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        const data = await getUsers();
        if (data) {
            setUsersList(data);
        }
    }

  return { usersList }
}