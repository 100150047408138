import * as Sentry from "@sentry/react";
import { useApi } from "../hooks/useApi";
import { useErrors } from "../hooks/useErrors";

export function usersService() {
    const { apiCall } = useApi();
    const { startLoading, stopLoading, setErrorState, clearError } = useErrors();

    const getUsers = async () => {
        const key = 'getUsers';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/get-users");
        startLoading(key);
        clearError(key);
        try {
            const response = await apiCall('GET', fileUrl);
            if (response.success) {
                return response.data;
            } else {
                setErrorState(key, "Error encontrando los usuarios.");
                Sentry.captureException(response, {
                    tags: { service: 'usersService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
                });
                return null;
            }
        } catch (error) {
            setErrorState(key, "Error encontrando los usuarios.");
            Sentry.captureException(error, {
                tags: { service: 'usersService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    };

    return { getUsers };
}
