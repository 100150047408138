import React, { useState, useEffect } from 'react';
import { Paper, Table, TableBody, TableContainer, TableHead } from '@mui/material';
import { InformesTableCell, InformesTableRow } from '../../../components/CustomStyles';
import { useResponsive } from '../../../hooks/useResponsive';
import { reportsCreditRiskService } from '../../../services/reportsCreditRiskService';
import { useErrors } from '../../../hooks/useErrors';
import InLineError from '../../../components/InLineError';
import LoadingSpinner from '../../../components/LoadingSpinner';
import useNumberFormatter from '../../../hooks/useNumberFormatter';

// Componente de fila para mostrar datos
const Row = ({ row, parameters }) => {
    const formatNumber = useNumberFormatter();

    const getCellAlignment = (value) => {
        return typeof value === 'number' ? 'right' : 'left';
    };

    return (
        <InformesTableRow>
            {parameters.map((param, index) => (
                <InformesTableCell key={index} sx={{ textAlign: getCellAlignment(row[param.key]) }}>
                    {typeof row[param.key] === 'number'
                        ? formatNumber(row[param.key])  // Formatear si es número
                        : row[param.key]  // Mostrar si es texto
                    }
                </InformesTableCell>
            ))}
        </InformesTableRow>
    );
}

export default function SummaryByEntityTable() {
    const { isBelowLgScreen } = useResponsive();
    const { loading, inLineErrors } = useErrors();
    const { getCreditRiskReportByEntity } = reportsCreditRiskService();
    const [parameters, setParameters] = useState([]);
    const [reportData, setReportData] = useState([]);

    useEffect(() => {
        fetchReportData();
    }, []);

    const fetchReportData = async () => {
        const response = await getCreditRiskReportByEntity({ year: 2024, month: "07", process_id: "dcaab6cc-7b97-4b64-8506-fd2b2f962ba9" });
        
        if (response) {
            const { parameters, current_values } = response;

            // Ordenar los parámetros según el orden de columna (column_order)
            const sortedParams = Object.keys(parameters).sort((a, b) => parameters[a].column_order - parameters[b].column_order);

            // Actualizar los parámetros en el estado
            setParameters(sortedParams.map(param => ({
                key: param,
                name: parameters[param].name
            })));

            // Ordenar los datos recibidos por entidad, epígrafe y categoría
            const sortedData = current_values.sort((a, b) => {
                if (a.id_entidad_contable < b.id_entidad_contable) return -1;
                if (a.id_entidad_contable > b.id_entidad_contable) return 1;
                if (a.Epigrafe < b.Epigrafe) return -1;
                if (a.Epigrafe > b.Epigrafe) return 1;
                if (a.id_categoria_exposicion < b.id_categoria_exposicion) return -1;
                if (a.id_categoria_exposicion > b.id_categoria_exposicion) return 1;
                return 0;
            });

            setReportData(sortedData);
        }
    };

    if (loading.getCreditRiskReportByEntity) {
        return <LoadingSpinner />;
    } else if (inLineErrors.getCreditRiskReportByEntity) {
        return <InLineError width={"100%"} length={"100%"} errorMessage={inLineErrors.getCreditRiskReportByEntity} />;
    } else {
        return (
            <TableContainer
                component={Paper}
                sx={{ boxShadow: 'none', mt: '7px', overflowX: isBelowLgScreen ? 'auto' : 'hidden' }}
            >
                <Table size='small' sx={{ borderCollapse: 'collapse', minWidth: '1250px' }} >
                    <TableHead>
                        <InformesTableRow>
                            {parameters.map((param, index) => (
                                <InformesTableCell key={index} sx={{ textAlign: 'left', minWidth: `${param.name.length}ch` }}>
                                    {param.name}
                                </InformesTableCell>
                            ))}
                        </InformesTableRow>
                    </TableHead>
                    <TableBody>
                        {reportData.length === 0 ? (
                            <InformesTableRow>
                                <InformesTableCell colSpan={parameters.length} sx={{ textAlign: 'center' }}>
                                    No hay datos para mostrar
                                </InformesTableCell>
                            </InformesTableRow>
                        ) : (
                            reportData.map((row, index) => (
                                <Row key={index} row={row} parameters={parameters} />
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}
