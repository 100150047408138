import * as Sentry from "@sentry/react";
import { useApi } from "../hooks/useApi";
import { useErrors } from "../hooks/useErrors";

export function loadFilesService() {
    const { apiCall, addQueryParameters, handleDownload } = useApi();
    const { startLoading, stopLoading, setErrorState, clearError, setDialogErrorState } = useErrors();

    const getLoadFiles = async ({ data_type, month, year, limit, start }) => {
        const key = 'getLoadFiles';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/load/" + data_type);
        var queryParams = { month, year, limit, start };
        fileUrl = addQueryParameters(fileUrl, queryParams);
        startLoading(key);
        clearError(key);
        try {
            const response = await apiCall('GET', fileUrl);
            if (response.success) {
                return response.data;
            }
            else {
                setErrorState(key, "Error buscando los ficheros de entrada.");
                Sentry.captureException(response, {
                    tags: { service: 'loadFilesService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
                });
                return null;
            }
        } catch (error) {
            setErrorState(key, "Error buscando los ficheros de entrada.");
            Sentry.captureException(error, {
                tags: { service: 'loadFilesService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    }

    const getLoadFile = async ({ data_type, file_id, month, year, fileDownloadName }) => {
        const key = 'getLoadFile';
        var fileUrl = new URL(window.REACT_APP_API_URL + '/load/' + data_type + '/' + file_id);
        var queryParams = {month: month, year: year};
        fileUrl = addQueryParameters(fileUrl, queryParams);
        startLoading(key);
        clearError(key);
        try {
            const response = await handleDownload(fileUrl, fileDownloadName);
            if (!response.success) {
                setDialogErrorState(key, "Error descargando el fichero de entrada.");
                Sentry.captureException(response, {
                    tags: { service: 'loadFilesService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
                });
                return null;
            }
        } catch (error) {
            setDialogErrorState(key, "Error descargando el fichero de entrada.");
            Sentry.captureException(error, {
                tags: { service: 'loadFilesService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    }

    const postLoadFile = async ({ formData, month, year, dataSet, fileType, validations }) => {
        const key = 'postLoadFile';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/load/" + fileType);
        var queryParams = { month: month, year: year, dataSet: dataSet, validations: validations };
        fileUrl = addQueryParameters(fileUrl, queryParams);
        startLoading(key);
        clearError(key);
        try {
            const response = await apiCall('POST', fileUrl, formData);
            console.log(response);
            console.log(response.status);
            if (!response.success) {
                if (response.statusCode === 210) {
                    setDialogErrorState(key, "El fichero existe, ya había sido insertado anteriormente.");
                } else {
                    setDialogErrorState(key, "Error subiendo el fichero de entrada.");
                }
                Sentry.captureException(response, {
                    tags: { service: 'loadFilesService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'POST' } },
                });
                return null;
            }
        } catch (error) {
            setDialogErrorState(key, "Error subiendo el fichero de entrada.");
            Sentry.captureException(error, {
                tags: { service: 'loadFilesService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'POST' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    }
    
    const putLoadFileDataSet = async ({ file_id, data_set }) => {
        const key = 'putLoadFileDataSet';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/update-file-dataset/" + file_id);
        var queryParams = { data_set };
        fileUrl = addQueryParameters(fileUrl, queryParams);
        startLoading(key);
        clearError(key);
        try {
            const response = await apiCall('PUT', fileUrl);
            if (!response.success) {
                setDialogErrorState(key, "Error actualizando la descripción del fichero.");
                Sentry.captureException(response, {
                    tags: { service: 'loadFilesService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'PUT' } },
                });
                return null;
            }
        } catch (error) {
            setDialogErrorState(key, "Error actualizando la descripción del fichero.");
            Sentry.captureException(error, {
                tags: { service: 'loadFilesService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'PUT' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    }

    return { getLoadFiles, getLoadFile, postLoadFile, putLoadFileDataSet }
}